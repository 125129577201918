import {
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { ReactElement, useEffect } from "react";
import { useSnackbar } from "notistack";

import "./DragDropFiles.css";

function formatFileSize(fileSize: number): string {
  const decimal = 2;
  const kilo = 1000;
  const mega = kilo * 1000;
  const giga = mega * 1000;

  if (!fileSize) {
    return "No file size";
  } else if (fileSize >= 0 && fileSize < kilo) {
    return `${fileSize} bytes`;
  } else if (fileSize >= kilo && fileSize < mega) {
    return `${(fileSize / kilo).toFixed(decimal)} KB`;
  } else if (fileSize >= mega && fileSize < giga) {
    return `${(fileSize / mega).toFixed(decimal)} MB`;
  } else if (fileSize >= giga) {
    return `${(fileSize / giga).toFixed(decimal)} GB`;
  } else {
    return "Negative file size";
  }
}

export const DragDropFiles = ({
  files,
  setFiles,
  extensions,
  text,
  logo,
}: {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  extensions: string[];
  text: string;
  logo: ReactElement;
}) => {
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: { "text/xlsx": extensions },
    });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setFiles((prevState) => [...prevState, ...acceptedFiles]);
    }
  }, [acceptedFiles, setFiles]);

  useEffect(() => {
    if (fileRejections.length > 0) {
      fileRejections.forEach((fileRejection) =>
        enqueueSnackbar(`${fileRejection.file.name} was rejected!`, {
          variant: "error",
        })
      );
    }
  }, [fileRejections, enqueueSnackbar]);

  return (
    <Container>
      <div className="dragDropField">
        {/* <div className="addedFiles">
          <List>
            {files?.map((file, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText
                  primary={file.name}
                  secondary={formatFileSize(file.size)}
                />
              </ListItem>
            ))}
          </List>
        </div> */}
        <div {...getRootProps({ className: "dropzone" })}>
          <div>{logo}</div>
          <input {...getInputProps()} />
          <Typography> Selected files:</Typography>
          <List>
            {files?.map((file, index) => (
              <ListItem key={index}>
                <ListItemIcon></ListItemIcon>
                <ListItemText
                  primary={file.name}
                  secondary={formatFileSize(file.size)}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    </Container>
  );
};
