import axios from "./axios";

export const postFormData = async function (
  endpoint: string,
  formData: FormData
) {
  return await axios.post(endpoint, formData, {
    responseType: "arraybuffer",
  });
};
