export enum Util {
  FileSplitter = "FileSplitter",
  AppendixComparison = "AppendixComparison",
  CommissionDater = "CommissionDater",
  NavHighlighter = "NavHighlighter",
  SelfDeclarationPDFs = "SelfDeclarationPDFs",
  CsvFileDownloader = "CsvFileDownloader",
}

export enum AppRole {
  DEV = "dev",
  DATA = "data",
  CM = "cm",
  ADMIN = "admin",
  FULL = "full",
  HR = "hr",
}
