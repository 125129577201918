import React from "react";
import classNames from "classnames";

import styles from "./Button.module.scss";

export interface ButtonProps {
  label: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  primary?: boolean;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  label,
  type = "button",
  primary = true,
  disabled = false,
  ...props
}) => {
  const buttonClasses = classNames(
    styles.acoButton,
    primary ? styles.primary : styles.secondary,
    disabled && styles.disabled
  );

  return (
    <button
      className={buttonClasses}
      type={type}
      disabled={disabled}
      {...props}
    >
      {label}
    </button>
  );
};

export default Button;
