/* eslint-disable react/jsx-no-undef */
import { useCallback, useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, Link, Navigate, useLocation } from "react-router-dom";
import {
  AppBar,
  createTheme,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  MenuOpen,
  DateRange,
  Difference,
  FolderCopy,
  BorderColor,
  PictureAsPdf,
  AttachFile,
} from "@mui/icons-material";
import FileSplitter from "./pages/FileSplitter";
import SelfDeclarationPDFs from "./pages/SelfDeclarationPDFs";
import AppendixComparison from "./pages/AppendixComparison";
import classNames from "classnames";
import CommissionDater from "./pages/CommissionDater";
import NavHighlighter from "./pages/NavHighlighter";
import Login from "./pages/Login";
import { useMsal } from "@azure/msal-react";
import { useAppUserContext } from "./components/AppContext";
import { Util } from "./common/types";
import CsvFileDownloader from "./pages/CsvFileDownloader";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#d89f5a",
    },
    primary: {
      main: "#7d8f80",
    },
  },
});

const utilsMap = {
  FileSplitter: "File Splitter",
  AppendixComparison: "Appendix Comparison",
  CommissionDater: "Commission Dater",
  NavHighlighter: "Nav Highlighter",
  SelfDeclarationPDFs: "Self Declaration PDFs",
  CsvFileDownloader: "CSV Downloader",
};

function App() {
  const { appUser, setAppUser } = useAppUserContext();
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const { instance: msalInstance } = useMsal();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.slice(1);
    if (Object.keys(utilsMap).includes(path)) {
      localStorage.setItem("currentUtil", location.pathname.slice(1));
    }
  }, [location.pathname]);

  const handleLogout = () => {
    setAppUser({
      userName: "",
      isLoggedIn: false,
      currentUtil: Util.AppendixComparison,
      appRoles: [],
    });
    msalInstance
      .logoutRedirect({
        account: msalInstance?.getActiveAccount(),
        postLogoutRedirectUri: "/Login",
      })
      .catch((error) => {
        alert(error);
      });
  };

  //Fires everytime a page is loaded
  const isLoggedIn = appUser.isLoggedIn;

  const handleUtilChange = useCallback((util: Util) => {
    setAppUser((prevState) => ({
      ...prevState,
      currentUtil: util,
    }));

    localStorage.setItem("currentUtil", util);
    setMenuOpen(false);
  }, []);

  const currentBackground =
    appUser.currentUtil === Util.CommissionDater ||
    appUser.currentUtil === Util.NavHighlighter
      ? `App-Filesplitter`
      : `App-Appendix`;
  const customClasses = classNames("App", currentBackground);

  return (
    <div className={customClasses}>
      <ThemeProvider theme={theme}>
        {appUser.isLoggedIn ? (
          <AppBar position="static" sx={{ color: "white" }}>
            <Toolbar>
              <IconButton
                size="medium"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setMenuOpen(!isMenuOpen)}
              >
                <MenuOpen />
              </IconButton>
              <Typography>{utilsMap[location.pathname.slice(1)]}</Typography>

              <div className="logout-div">
                {appUser.userName || "N/A"}
                <span className="logout" onClick={handleLogout}>
                  Logout
                </span>
              </div>
            </Toolbar>
          </AppBar>
        ) : (
          <AppBar position="static" sx={{ color: "white" }}>
            <Toolbar />
          </AppBar>
        )}

        <Drawer
          anchor="left"
          open={isMenuOpen}
          onClose={() => setMenuOpen(false)}
        >
          <List>
            <Link to={`/AppendixComparison`} className={"Menu-item"}>
              <ListItem
                key={"Appendix Comparison"}
                onClick={() => handleUtilChange(Util.AppendixComparison)}
              >
                <ListItemIcon>{<Difference />}</ListItemIcon>
                <ListItemText primary={"Appendix Comparison"} />
              </ListItem>
            </Link>

            <Link to={`/CommissionDater`} className={"Menu-item"}>
              <ListItem
                key={"CommissionDater"}
                onClick={() => handleUtilChange(Util.CommissionDater)}
              >
                <ListItemIcon>{<DateRange />}</ListItemIcon>
                <ListItemText primary={"Commisision Dater"} />
              </ListItem>
            </Link>

            <Link to={`/FileSplitter`} className={"Menu-item"}>
              <ListItem
                key={"File Splitter"}
                onClick={() => handleUtilChange(Util.FileSplitter)}
              >
                <ListItemIcon>{<FolderCopy />}</ListItemIcon>
                <ListItemText primary={"File Splitter"} />
              </ListItem>
            </Link>

            <Link to={`/NavHighlighter`} className={"Menu-item"}>
              <ListItem
                key={"Nav Highlighter"}
                onClick={() => handleUtilChange(Util.NavHighlighter)}
              >
                <ListItemIcon>{<BorderColor />}</ListItemIcon>
                <ListItemText primary={"Nav Highlighter"} />
              </ListItem>
            </Link>

            <Link to={"/SelfDeclarationPDFs"} className={"Menu-item"}>
              <ListItem
                key={"Self Declaration PDFs"}
                onClick={() => handleUtilChange(Util.SelfDeclarationPDFs)}
              >
                <ListItemIcon>{<PictureAsPdf />}</ListItemIcon>
                <ListItemText primary={"Self Declaration PDFs"} />
              </ListItem>
            </Link>

            <Link to={"/CsvFileDownloader"} className={"Menu-item"}>
              <ListItem
                key={"CSV File Downaloader"}
                onClick={() => handleUtilChange(Util.CsvFileDownloader)}
              >
                <ListItemIcon>{<AttachFile />}</ListItemIcon>
                <ListItemText primary={"CSV Downloader"} />
              </ListItem>
            </Link>
          </List>
        </Drawer>

        <Routes>
          <Route path={`/`} element={<Navigate to="/Login" />} />
          <Route path={`/Login`} element={<Login />} />
          <Route
            path={`/NotFound`}
            element={
              <div>
                <h1>NOT FOUND</h1>
              </div>
            }
          />

          <Route path={`/FileSplitter`} element={<FileSplitter />} />

          <Route
            path={`/SelfDeclarationPDFs`}
            element={<SelfDeclarationPDFs />}
          />

          <Route
            path={`/AppendixComparison`}
            element={<AppendixComparison />}
          />
          <Route path={`/NavHighlighter`} element={<NavHighlighter />} />
          <Route path={`/CommissionDater`} element={<CommissionDater />} />
          <Route path={`/CsvFileDownloader`} element={<CsvFileDownloader />} />

          <Route
            path={"*"}
            element={<Navigate to={isLoggedIn ? "/NotFound" : "Login"} />}
          />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
