import { useSnackbar } from "notistack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Button, CircularProgress, TextField } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import { AxiosError } from "axios";
import { handleMerlinError } from "../../common/error";
import fileDownload from "js-file-download";
import { REACT_APP_VALIANT_API_ORIGIN } from "../../common/config";
import axios from "../../common/axios";
import { useNavigate } from "react-router-dom";
import { useAppUserContext } from "../../components/AppContext";
import ForbiddenApp from "../UtilAuthorizator";
import { AppRole } from "../../common/types";

const REACT_APP_COMMISSION_DATER_ROLES = [
  AppRole.ADMIN,
  AppRole.FULL,
  AppRole.DEV,
  AppRole.DATA,
];

export const CommissionDater = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { appUser } = useAppUserContext();

  if (!appUser.isLoggedIn) {
    navigate("/Login");
  }

  const handleDateChange = (newValue: any) => {
    setDate(format(newValue, "yyyy-MM-dd"));
  };

  return (
    <ForbiddenApp utilRoles={REACT_APP_COMMISSION_DATER_ROLES}>
      <div>
        {!loading ? (
          <div>
            <p>{`Select a date for the commission:`}</p>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date"
                inputFormat="yyyy-MM-dd"
                value={date}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <div></div>
            <div>
              <Button
                sx={{ mx: 0.5, color: "white", margin: "10px" }}
                className="My-button"
                variant="contained"
                color="primary"
                onClick={async () => {
                  try {
                    setLoading(true);
                    const response = await axios.get(
                      REACT_APP_VALIANT_API_ORIGIN +
                        `/merlin/getCommissionDataReport?date=${date}`
                    );

                    enqueueSnackbar("Success, downloading file.", {
                      variant: "success",
                    });

                    fileDownload(
                      response.data,
                      `Dated Commissions - ${date}.csv`
                    );
                    setLoading(false);
                  } catch (error) {
                    console.log(error);
                    enqueueSnackbar(
                      await handleMerlinError(error as AxiosError),
                      {
                        variant: "error",
                      }
                    );
                    setLoading(false);
                  }
                }}
              >
                Send
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <p>Please wait:</p>
            <CircularProgress sx={{ mx: 0.5, my: 6.0 }} className="loading" />
          </div>
        )}
      </div>
    </ForbiddenApp>
  );
};
