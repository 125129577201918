import { useMsal } from "@azure/msal-react";
import React, {
  useState,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useEffect,
  ReactNode,
} from "react";
import { AppRole, Util } from "../../common/types";

type IAppUser = {
  userName: string;
  isLoggedIn: boolean;
  currentUtil: Util;
  appRoles: AppRole[];
};

const fallbackUser: IAppUser = {
  userName: "",
  isLoggedIn: false,
  currentUtil: Util.AppendixComparison,
  appRoles: [],
};

type ContextValue = {
  appUser: IAppUser;
  setAppUser: Dispatch<SetStateAction<IAppUser>>;
};
const initialValue: ContextValue = {
  appUser: fallbackUser,
  setAppUser: (): void => {},
};

export const AppUserContext = React.createContext<ContextValue>(initialValue);
AppUserContext.displayName = "AppUserContext";

interface Props {
  children?: ReactNode;
}

export const AppUserContextProvider: React.FC<Props> = ({ children }) => {
  const { instance: msalInstance } = useMsal();
  const [appUser, setAppUser] = useState<IAppUser>(fallbackUser);

  useEffect(() => {
    try {
      const account = msalInstance.getActiveAccount();
      const currentUtil =
        localStorage.getItem("currentUtil") !== "undefined"
          ? (localStorage.getItem("currentUtil") as Util)
          : Util.AppendixComparison;

      const currentAppRoles: AppRole[] = account?.idTokenClaims?.roles
        ? (account?.idTokenClaims?.roles as AppRole[])
        : [];

      console.log(currentAppRoles);

      if (account) {
        const currentUser = {
          userName: account?.name,
          isLoggedIn: true,
          currentUtil: currentUtil,
          appRoles: currentAppRoles,
        };
        setAppUser(currentUser);
      } else {
        setAppUser(fallbackUser);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const contextValue = useMemo<ContextValue>(
    () => ({ appUser, setAppUser }),
    [appUser]
  );

  return (
    <AppUserContext.Provider value={contextValue}>
      {children}
    </AppUserContext.Provider>
  );
};

export const useAppUserContext = (): ContextValue => useContext(AppUserContext);
