import React from "react";
import ReactDOM from "react-dom/client";
// import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./common/config";
import { MsalProvider } from "@azure/msal-react";
import { AppUserContextProvider } from "./components/AppContext";
import { BrowserRouter } from "react-router-dom";

export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
document.title = "Valiant";
root.render(
  <MsalProvider instance={msalInstance}>
    <AppUserContextProvider>
      <SnackbarProvider maxSnack={3}>
        <React.StrictMode>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </React.StrictMode>
      </SnackbarProvider>
    </AppUserContextProvider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
