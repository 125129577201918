import Axios from "axios";
import { msalInstance } from "..";
import { loginRequest } from "./config";

const axiosInstance = Axios.create();

axiosInstance.interceptors.response.use(
  (response: any) => response,
  async (error: any) => {
    const {
      config,
      response: { status },
    } = error;
    //@TODO Consider checking error message too: if (status === 401 && error.response.data.error === "invalid_token")
    if (status !== 401) {
      throw error;
    }

    if (status === 401) {
      const { _retry: hasRetried } = config;
      if (hasRetried) {
        config._retry = false;
        config.headers.authorization = "";
        window.location.assign("/login");
        return axiosInstance.request(config);
      }
      try {
        const token = await msalInstance.acquireTokenSilent({
          ...loginRequest,
          account: msalInstance.getActiveAccount(),
        });
        axiosInstance.defaults.headers[
          "authorization"
        ] = `Bearer ${token.accessToken}`;
        config.headers.authorization = `Bearer ${token.accessToken}`;
        config._retry = true;
        return axiosInstance.request(config);
      } catch (error) {
        //@TODO Consider redirecting the user to the login page instead of giving them another popup window to sign in
        try {
          const token = await msalInstance.acquireTokenPopup({
            ...loginRequest,
            account: msalInstance.getActiveAccount(),
          });
          axiosInstance.defaults.headers[
            "authorization"
          ] = `Bearer ${token.accessToken}`;
          config.headers.authorization = `Bearer ${token.accessToken}`;
          return axiosInstance.request(config);
        } catch (error) {
          console.log(error);
          window.location.assign("/login");
          throw error;
        }
      }
    }
  }
);

export default axiosInstance;
