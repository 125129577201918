import { LogLevel } from "@azure/msal-browser";

export const {
  REACT_APP_VALIANT_FUNCTION_KEY = "",
  REACT_APP_MERLIN_COMMISSION_DATER_FUNCTION_KEY = "",
  REACT_APP_MSAL_CLIENT_ID = "",
  REACT_APP_MSAL_AUTHORITY = "",
  REACT_APP_MSAL_REDIRECT_URI = "",
  REACT_APP_VALIANT_API_ORIGIN = "",
  REACT_APP_SD_EXCEL_TO_PDFS_URL = "",
  //REACT_APP_SHOW_UNFINISHED_FEATURES_FLAG = "",
} = process.env;

// export const SHOW_UNFINISHED_FEATURES =
//   REACT_APP_SHOW_UNFINISHED_FEATURES_FLAG.toLowerCase() === "enabled";

export const msalConfig = {
  auth: {
    clientId: REACT_APP_MSAL_CLIENT_ID,
    authority: REACT_APP_MSAL_AUTHORITY,
    redirectUri: REACT_APP_MSAL_REDIRECT_URI,
  },
  navigateToLoginRequestUrl: false,
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ["User.Read"],
};
