import { NoAccounts } from "@mui/icons-material";
import "./UtilAuthorizator.css";
import React, { useMemo, PropsWithChildren } from "react";
import { useAppUserContext } from "../../components/AppContext";

type Props = {
  utilRoles: any[];
};

export const UtilAuthorizator = (props: PropsWithChildren<Props>) => {
  const { appUser } = useAppUserContext();

  const isUnauthorized = useMemo(
    () =>
      appUser.appRoles &&
      !appUser.appRoles.some((role) => props.utilRoles.includes(role)),
    [appUser.appRoles, props.utilRoles]
  );

  return (
    <>
      {isUnauthorized ? (
        <div className="forbiddenAppBody">
          <NoAccounts className="forbiddenAppLogo"></NoAccounts>
          <p>
            <b>This Utility App is not available for your Role.</b>
          </p>
        </div>
      ) : (
        props.children
      )}
    </>
  );
};
