import { AxiosError } from "axios";

export function handleResponseError(error: AxiosError) {
  if (!error.response?.data) {
    return error.message;
  }

  if (error.request.responseType === "arraybuffer") {
    return new TextDecoder("utf-8").decode(
      (error as AxiosError).response?.data as ArrayBuffer
    );
  } else {
    return error.response?.data as string;
  }
}

export function handleMerlinError(error: AxiosError) {
  if (!error.response?.data) {
    return error.message;
  }

  if (error.request.responseType === "arraybuffer") {
    return new TextDecoder("utf-8").decode(
      (error as AxiosError).response?.data as ArrayBuffer
    );
  } else {
    //@ts-ignore
    return error.response?.data?.error?.message as string;
  }
}
