import { useState } from "react";
import { CircularProgress, Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppUserContext } from "../../components/AppContext";
import { useSnackbar } from "notistack";
import DragDropFiles from "../../components/DragDropFiles";
import { postFormData } from "../../common/response";
import { AttachFile } from "@mui/icons-material";
import { handleResponseError } from "../../common/error";
import { AxiosError } from "axios";
import fileDownload from "js-file-download";
import { REACT_APP_VALIANT_API_ORIGIN } from "../../common/config";

export const CsvFileDownloader = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { appUser } = useAppUserContext();

  const { enqueueSnackbar } = useSnackbar();

  if (!appUser.isLoggedIn) {
    navigate("/Login");
  }

  return (
    <Container>
      <div>
        <div>
          <p>{`Select or drag csv files for downloading:`}</p>
        </div>
        {!loading ? (
          <DragDropFiles
            files={files}
            setFiles={setFiles}
            extensions={[".csv"]}
            text={"Drag & drop csv files here, or click to select files"}
            logo={<AttachFile className="centered"></AttachFile>}
          />
        ) : (
          <CircularProgress className="loading" />
        )}
      </div>
      <>
        <Button
          sx={{ mx: 0.5, color: "white" }}
          disabled={loading || files.length === 0}
          variant="contained"
          color="secondary"
          onClick={async () => {
            setLoading(true);
            try {
              const formData: any = new FormData();

              files.forEach((file, index) => {
                formData.append(`files${index}`, file);
              });
              const response = await postFormData(
                `${REACT_APP_VALIANT_API_ORIGIN}/valiant/zipFiles`,
                formData
              );

              setLoading(false);
              enqueueSnackbar("Success, downloading file.", {
                variant: "success",
              });
              setFiles([]);

              fileDownload(response.data, `Files.zip`);
            } catch (error) {
              console.log(handleResponseError(error as AxiosError));
              enqueueSnackbar(handleResponseError(error as AxiosError), {
                variant: "error",
              });
              setFiles([]);
              setLoading(false);
            }
          }}
        >
          Process
        </Button>
        <Button
          sx={{ mx: 0.5, color: "white" }}
          className="My-button"
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={() => {
            setFiles([]);
          }}
        >
          Reset
        </Button>
      </>
    </Container>
  );
};
