/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import styles from "./Login.module.scss";
import { AuthenticationResult } from "@azure/msal-browser";
import axiosInstance from "../../common/axios";
import { loginRequest } from "../../common/config";
import Loader from "../../components/Loader";
import Button from "../../components/Button";
import { useAppUserContext } from "../../components/AppContext";
import { AppRole, Util } from "../../common/types";

const Login = () => {
  const { appUser, setAppUser } = useAppUserContext();

  const { instance: msalInstance } = useMsal();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleResponse = useCallback(
    (response: AuthenticationResult) => {
      if (!response) {
        return;
      }

      const { accessToken, account } = response;
      msalInstance.setActiveAccount(account);
      axiosInstance.defaults.headers["authorization"] = `Bearer ${accessToken}`;

      const currentUtil = Object.values(Util).includes(
        localStorage.getItem("currentUtil") as Util
      )
        ? (localStorage.getItem("currentUtil") as Util)
        : (() => {
            localStorage.setItem("currentUtil", Util.AppendixComparison);
            return Util.AppendixComparison;
          })();

      const currentAppRoles: AppRole[] =
        (account?.idTokenClaims?.roles as AppRole[]) ?? [];

      setAppUser({
        userName: account?.name,
        isLoggedIn: true,
        currentUtil: currentUtil,
        appRoles: currentAppRoles,
      });

      navigate("/" + currentUtil);
    },
    [msalInstance]
  );

  useEffect(() => {
    if (appUser.isLoggedIn) {
      if (
        !Object.values(Util).includes(
          localStorage.getItem("currentUtil") as Util
        )
      ) {
        localStorage.setItem("currentUtil", Util.AppendixComparison);
      }
      const currentUtil = localStorage.getItem("currentUtil") as Util;
      navigate("/" + currentUtil);
    }
    setIsLoading(true);

    msalInstance.initialize().then(() =>
      msalInstance
        .handleRedirectPromise()
        .then(handleResponse)
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false))
    );
  }, []);

  const handleLogIn = useCallback(() => {
    msalInstance
      .loginRedirect(loginRequest)
      .catch((error) => console.error(error));
  }, [msalInstance, loginRequest]);

  return (
    <div className={styles.LoginWrapper}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.LoginContent}>
          <h1>Acolin VALIANT</h1>
          <Button label="Sign in with Microsoft" onClick={handleLogIn} />
        </div>
      )}
    </div>
  );
};

export default Login;
