import React from "react";
import classNames from "classnames";

//import loader from "../../assets/Acolin-Spinner.gif";

import styles from "./Loader.module.scss";
import { CircularProgress } from "@mui/material";

interface Props {
  className?: string;
}

const Loader: React.FC<Props> = ({ className }) => (
  <div className={classNames(styles.loader, className)}>
    <CircularProgress size={150} />
  </div>
);

export default Loader;
