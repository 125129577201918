import { useState } from "react";
import { Button, CircularProgress, Container } from "@mui/material";
import DragDropFiles from "../../components/DragDropFiles";
import { AxiosError } from "axios";
import "./FileSplitter.css";
import { useSnackbar } from "notistack";
import fileDownload from "js-file-download";
import { handleResponseError } from "../../common/error";
import { postFormData } from "../../common/response";
import { REACT_APP_VALIANT_API_ORIGIN } from "../../common/config";

import { FolderCopy } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAppUserContext } from "../../components/AppContext";
import { AppRole } from "../../common/types";
import ForbiddenApp from "../UtilAuthorizator";

const REACT_APP_FILE_SPLITTER_ROLES = [
  AppRole.ADMIN,
  AppRole.FULL,
  AppRole.DEV,
  AppRole.CM,
];

export const FileSplitter = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { appUser } = useAppUserContext();

  if (!appUser.isLoggedIn) {
    navigate("/Login");
  }

  return (
    <ForbiddenApp utilRoles={REACT_APP_FILE_SPLITTER_ROLES}>
      <Container>
        <div>
          <p>Select or drag a .xlsx file for splitting:</p>
        </div>
        <div>
          {!loading ? (
            <DragDropFiles
              files={files}
              setFiles={setFiles}
              extensions={[".xlsx", ".json"]}
              text={
                "Drag & drop clean excel files here, or click to select files"
              }
              logo={<FolderCopy className="centered"></FolderCopy>}
            />
          ) : (
            <CircularProgress className="loading" />
          )}
        </div>
        <div>
          <Button
            sx={{ mx: 0.5, color: "white" }}
            disabled={loading}
            variant="contained"
            color="secondary"
            onClick={async () => {
              const formData: any = new FormData();

              files.map((file, index) => {
                formData.append(`files${index}`, file);
              });
              setLoading(true);

              try {
                const response = await postFormData(
                  REACT_APP_VALIANT_API_ORIGIN + "/valiant/splitfiles",
                  formData
                );

                const archiveFilename =
                  files.length > 1
                    ? `FileSplitter - ${
                        files.length
                      } Files - ${new Date().toISOString()}.zip`
                    : `${files[0].name}.zip`;

                //TODO Try with canonical way again
                fileDownload(response.data, archiveFilename);

                setLoading(false);
                enqueueSnackbar("Success, downloading archive.", {
                  variant: "success",
                });
                setFiles([]);
              } catch (error) {
                enqueueSnackbar(
                  await handleResponseError(error as AxiosError),
                  {
                    variant: "error",
                  }
                );
                setFiles([]);
                setLoading(false);
              }
            }}
          >
            SPLIT
          </Button>
          <Button
            sx={{ mx: 0.5, color: "white" }}
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={() => {
              setFiles([]);
            }}
          >
            RESET
          </Button>
        </div>
      </Container>
    </ForbiddenApp>
  );
};
